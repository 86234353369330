<template>
  <div class="cont-warp">
    <!--轮播图-->
    <carousel :lunboData="lunboData"></carousel>
    <div>
      <!-- 培训 -->
      <img src="../../../assets/images/securityTrain/h5/home/pic01.png" class="icon-img"/>
      <div class="item-cont">
        <div class="item">
          <router-link to="/h5CdsoPage">
            <div class="item-top back01">
              <div class="text">
                <p>注册数据安全官</p>
                <p>CDSO</p>
              </div>
            </div>
            <div class="item-text">
              <div class="text-p">
                <p>着眼于数据安全顶层规划设计与统筹管理，旨在培养业界一流的数据安全领导者。</p>
              </div>
              <p class="btn back-btn01">查看详情 》</p>
            </div>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/h5CdsePage">
            <div class="item-top  back02">
              <div class="text">
                <p>注册数据安全工程师</p>
                <p>CDSE</p>
              </div>
            </div>
            <div class="item-text">
              <div class="text-p">
                <p>着眼于数据安全顶层规划设计与统筹管理，旨在培养业界一流的数据安全领导者。</p>
              </div>
              <p class="btn back-btn02">查看详情 》</p>
            </div>
          </router-link>
        </div>
        <div class="item">
          <router-link to="/H5DsmmPage">
            <div class="item-top back03">
              <div class="text">
                <p>DSMM测评师</p>
              </div>
            </div>
            <div class="item-text">
              <div class="text-p">
                <p>着眼于DSMM（数据安全能力成熟度模型）测试评估，旨在培养专业的DSMM测评师。</p>
              </div>
              <p class="btn back-btn03">查看详情 》</p>
            </div>
          </router-link>
        </div>
        <div class="item no-click">
          <div class="item-top back04">
            <div class="text">
              <p>定制班培养</p>
            </div>
          </div>
          <div class="item-text">
            <div class="text-p">
              <p>行业不同，数据要素不同；产业不同，数据安全实施方案不同；角色不同，数据安全岗位职责不同。</p>
              <p>我们可根据您的需求量身定做。</p>
            </div>
            <a href="tel:18612733266" class="mobile"><p class="btn back-btn04">拨打电话 》</p></a>
          </div>
        </div>
      </div>
      <!--  认证-->
      <img src="../../../assets/images/securityTrain/h5/home/pic02.png" class="icon-img"/>
      <div class="assess-cont">
        <div class="assess-item">
          <div class="assess-text">
            <h3 class="s1">数据安全评估</h3>
            <p>根据国家标准GB/T37988-2019《信息安全技术数据安全能力成熟度模型》，评估企业或组织的数据安全能力成熟度等级，帮助企业或组织识别数据安全风险，为企业或组织的数据安全治理能力提供标准依据与背书。</p>
            <router-link to="/h5AssessPage"><p class="btns back-btn01">查看详情 》</p></router-link>
          </div>
        </div>
        <div class="assess-item">
          <div class="assess-text">
            <h3 class="s1">数据安全认证</h3>
            <p>根据国家标准GB/T37988-2019《信息安全技术数据安全能力成熟度模型》，开展数据安全能力成熟度测评相关工作，对企业或组织颁发<span
                class="red">国家认监委认证的数据安全能力成熟度等级证书</span>。</p>
            <router-link to="/h5ApprovePage"><p class="btns back-btn02">查看详情 》</p></router-link>
          </div>
        </div>
      </div>
      <!-- 优势特色-->
      <div class="edge-features-cont">
        <div class="edge-cont">
          <div class="edge-cont-item">
            <router-link to="/h5About">
              <div class="edge-item">
                <div class="edge-name">
                  <p>国家级别</p>
                </div>
                <div class="edge-text">
                  <p>数据安全领域<span class="red">第一个国家级研究中心 </span></p>
                  <p>国内数据安全领域<span class="red">第一个国家级标准</span></p>
                  <h4 class="p1">查看详情</h4>
                </div>
              </div>
            </router-link>
          </div>
          <div class="edge-cont-item">
            <router-link to="/h5ApprovePage">
              <div class="edge-item">
                <div class="edge-name">
                  <p>权威认证</p>
                </div>
                <div class="edge-text">
                  <p>最早：国家认监委批复的国内<span class="red">第一块DSMM牌照</span></p>
                  <p>最优：国家信安标委优秀实践案例评选<span class="red">一等奖</span></p>
                  <h4 class="p1">查看详情</h4>
                </div>
              </div>
            </router-link>
          </div>
          <div class="edge-cont-item">
            <router-link to="/h5TeacherPage">
              <div class="edge-item">
                <div class="edge-name">
                  <p>顶级师资</p>
                </div>
                <div class="edge-text">
                  <p><span class="red">权威</span>标准化专家、<span class="red">资深</span>认证专家</p>
                  <p><span class="red">顶级</span>企业大咖、<span class="red">前沿</span>学者教授</p>
                  <h4 class="p1">查看详情</h4>
                </div>
              </div>
            </router-link>
          </div>
          <div class="edge-cont-item">
            <router-link to="/h5CoursePage">
              <div class="edge-item">
                <div class="edge-name">
                  <p>理实结合</p>
                </div>
                <div class="edge-text">
                  <p>理论联系实际、<span class="red">最前沿</span>标准引领</p>
                  <p>最佳实践案例第一个国家级标准</p>
                  <h4 class="p1">查看详情</h4>
                </div>
              </div>
            </router-link>
          </div>
          <div class="edge-cont-item">
            <router-link to="/h5Advantage">
              <div class="edge-item">
                <div class="edge-name">
                  <p>一考双证</p>
                </div>
                <div class="edge-text">
                  <p>获得研究中心证书</p>
                  <p>获得工信部人才证书</p>
                  <h4 class="p1">查看详情</h4>
                </div>
              </div>
            </router-link>
          </div>
        </div>
      </div>
      <img src="../../../assets/images/securityTrain/h5/home/pic04.png"/>
      <img src="../../../assets/images/securityTrain/h5/home/pic05.png"/>
      <erweima></erweima>
<!--      <img src="../../../assets/images/securityTrain/h5/home/pic06.png" style="margin-bottom: 30px;"/>-->
    </div>
    <div style="height: 60px"></div>
    <!--电 话-->
    <pub-footer></pub-footer>
  </div>
</template>

<script>
import Carousel from './pub/carousel'
import PubFooter from './pub/pubFooter'
import Erweima from "./pub/erweima";

export default {
  name: '',
  components: {
    Carousel,
    PubFooter,
    Erweima
  },
  data() {
    return {
      //轮播图数据
      lunboData: [
        {
          imgUrl: require("../../../../src/assets/images/securityTrain/h5/home/banner01.png"),
          pathUrl: "/h5CdsoPage",
          id: 1
        },
        {
          imgUrl: require("../../../../src/assets/images/securityTrain/h5/home/banner02.png"),
          pathUrl: "/h5CdsePage",
          id: 2
        },
        {
          imgUrl: require("../../../../src/assets/images/securityTrain/h5/home/banner03.png"),
          pathUrl: "/h5DsmmPage",
          id: 3
        },
        {
          imgUrl: require("../../../../src/assets/images/securityTrain/h5/home/banner04.png"),
          pathUrl: "/h5ApprovePage",
          id: 4
        },
        {
          imgUrl: require("../../../../src/assets/images/securityTrain/h5/home/banner05.png"),
          pathUrl: "",
          id: 5
        },
        {
          imgUrl: require("../../../../src/assets/images/securityTrain/h5/home/banner06.png"),
          pathUrl: "",
          id: 6
        },
      ]
    }
  },
  methods: {
    _isMobile() {
      let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag;
    },
  },
  mounted() {
    if (this._isMobile()) {
      this.$router.replace('h5SecurityTrain');
    } else {
      this.$router.replace('securityTrain');
    }
  },
}
</script>
<style>
.pay-cost-page{
  display: none;
}
</style>
<style lang="less" scoped>
.cont-warp {
  width: 100%;
  margin: 0 auto;

  img {
    max-width: 100%;
    margin-top: 30px;
  }

  .icon-img {
    max-width: 225px !important;
    height: 31.5px;
    text-align: center;
  }

  .item-cont {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 10px;
    width: 95%;
    margin: 0 auto;

    .item {
      width: 48%;
      background: #FFFFFF;
      border: 1px solid #D8D8D8;
      border-radius: 10px;
      cursor: pointer;
      margin-top: 20px;

      .item-top {
        width: 100%;
        height: 75px;
        border-radius: 10px 10px 0px 0px;
        display: -webkit-box;
        -webkit-box-orient: horizontal;
        -webkit-box-pack: center;
        -webkit-box-align: center;

        p {
          font-size: 16px;
          color: #FFFFFF;
          line-height: 30px;
        }
      }

      .item-text {
        padding: 10px;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        line-height: 23px;

        .text-p {
          height: 130px;
          text-align: left;
        }
      }

      .btn {
        width: 92px;
        height: 30px;
        line-height: 30px;
        border-radius: 25px;
        margin-left: 20%;
      }

      .phone-text {
        height: 90px;
      }
    }

  }
}

.back01 {
  background: #0079F4;
}

.back02 {
  background: #F79D0F;
}

.back03 {
  background: #0EC6D2;
}

.back04 {
  background: #7C51F8;
}

.back-btn01 {
  background: #EAF4FF;
  color: #0079F4 !important;
}

.back-btn02 {
  background: #FFF4E2;
  color: #F79D0F !important;
}

.back-btn03 {
  background: #EBFAFB;
  color: #0EC6D2 !important;
}

.back-btn04 {
  background: #F5F2FF;
  color: #7C51F8 !important;
}


//认证
.assess-cont {
  margin-top: 30px;

  .assess-item {
    width: 95%;
    margin: 0 auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 16px 0px rgba(23, 11, 0, 0.06);
    border-radius: 10px;

    .assess-text {
      padding: 10px;
      margin-bottom: 20px;

      h3 {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
        color: #000000;
        line-height: 35px;
        margin-bottom: 25px;
      }

      .s1 {
        &:before {
          content: "";
          display: inline-block;
          width: 31px;
          height: 27px;
          background: url("../../../../src/assets/images/securityTrain/pc/icon01.png");
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      .s2 {
        &:before {
          content: "";
          display: inline-block;
          width: 27px;
          height: 27px;
          background: url("../../../../src/assets/images/securityTrain/pc/icon02.png");
          vertical-align: middle;
          margin-right: 10px;
        }
      }

      p {
        text-align: left;
        font-size: 13px;
        font-weight: 400;
        color: #333333;
        line-height: 23px;
      }

      .btns {
        width: 92px;
        height: 30px;
        border-radius: 15px;
        line-height: 30px;
        text-align: center;
        margin-top: 20px;

        a {
          display: inline-block;
        }
      }
    }
  }
}

.p1 {
  color: #0079F4 !important;
  width: 100px;
  text-align: left;
  vertical-align: middle;
  font-size: 12px;

  &:after {
    content: "";
    display: inline-block;
    width: 4.5px;
    height: 8.5px;
    background: url("../../../../src/assets/images/securityTrain/h5/home/view.png") no-repeat;
    background-size: 4.5px 8.5px;
    margin-left: 5px;
  }
}

.red {
  color: #FF3434;
}

//优势特色
.edge-features-cont {
  width: 100%;
  height: 600px;
  background: url("../../../../src/assets/images/securityTrain/h5/home/pic03.png") no-repeat;
  background-size: 100% 600px;

  .edge-cont {
    padding-top: 20%;
    width: 95%;
    margin: 0 auto;

    .edge-cont-item {
      //margin: 0 20px;
      width: 100%;
      background: #FFFFFF;
      border-radius: 9px;
      margin-bottom: 11px;

      .edge-item {
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .edge-name {
          background: url("../../../../src/assets/images/securityTrain/h5/home/pic03-left.png") no-repeat;
          width: 71px;
          height: 64px;
          position: relative;
          background-size: 100%;

          p {
            position: absolute;
            top: 30%;
            left: 22%;
            width: 34px;
            font-size: 17px;
            font-weight: bold;
            color: #FFFFFF;
          }
        }

        .edge-text {
          width: 75%;
          //padding-top: 3%;
          line-height: 20px;

          p {
            font-size: 12px;
            color: #000000;
            //line-height: 42px;
            text-align: left;
          }
        }


      }
    }
  }

}
</style>
